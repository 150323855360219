<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-container>
      <!-- Seleção de Equipamento Pai -->
      <v-row>
        <v-col cols="5">
          <v-autocomplete
            :loading="isLoadingEquipamento"
            v-model="selectedEquipamentoPai"
            :items="equipamentos"
            item-value="id"
            item-text="nome"
            label="Selecione o Equipamento Principal"
            outlined
            clearable
            single-line
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="4" class="mt-4">
            <h3 >Equipamento Principal Selecionado:</h3>
        </v-col>
        <v-col class="mt-4">
          <p v-if="selectedEquipamentoPai" class="blod_color mt-0" style="font-size: 22px;">
            {{ getEquipamentoNome(selectedEquipamentoPai) }}
          </p>
          <p v-else style="margin: 0;">
            Nenhum equipamento principal selecionado.
          </p>
        </v-col>
      </v-row>
      <!-- Exibição de Equipamentos Dependentes em duas colunas -->
      <v-row>
        <v-col cols="5">
          <h3>Equipamentos Dependentes Selecionados:</h3>
          <ul>
            <li
              v-for="equip in dependentesColuna1"
              :key="equip"
              class="blod_color"
              style="font-size: 18px;"
            >
              {{ getEquipamentoNome(equip) }}
            </li>
          </ul>
        </v-col>
        <v-col cols="3" v-if="selectedEquipamentosFilhos.length > 1">
        <br>
          <ul>
            <li
              v-for="equip in dependentesColuna2"
              :key="equip"
              class="blod_color"
              style="font-size: 18px;"
            >
              {{ getEquipamentoNome(equip) }}
            </li>
          </ul>
        </v-col>
        <v-col cols="4">
          <h3 >Esterilizáveis Dependentes Selecionados:</h3> <!-- Alinha o título -->
          <ul>
            <li
              v-for="equip in dependentesColuna3"
              :key="equip"
              class="blod_color"
              style="font-size: 18px;"
            >
              {{ getEsterilizavelNome(equip) }}
            </li>
          </ul>
        </v-col>
      </v-row>
       
      <v-row>
        <v-col cols="5">
          <v-autocomplete
          :loading="isLoadingEquipamento"
            v-model="selectedEquipamentosFilhos"
            :items="filteredEquipamentos"
            item-value="id"
            item-text="nome"
            label="Selecione os Equipamentos Dependetes"
            multiple
            chips
            outlined
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="5">
          <v-autocomplete
          :loading="isLoadingEsterilizavel"
            v-model="selectedEsterilizavel"
            :items="esterilizavel"
            item-value="id"
            item-text="nome"
            label="Selecione os Esterilizaveis"
            multiple
            chips
            outlined
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col class="text-end mt-4">
          <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
        </v-col>
      </v-row>

      <!-- Botão Salvar -->
      <v-row class="justify-end mt-0" dense>
    </v-row>

      <!-- Listar Seleções -->
      <v-row>
        
      </v-row>
    </v-container>
  </v-form>
</template>




<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';

export default {
  name: "EditarCadastrarEquipamentoDependente",
  components: { ConfirmButton },
  props: {
    currentItem: Object, // Item atual para edição
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      equipamentos: [], // Lista de todos os equipamentos disponíveis
      esterilizavel: [],
      selectedEquipamentoPai: null, // ID do equipamento pai selecionado
      selectedEquipamentosFilhos: [], // IDs dos equipamentos filhos selecionados
      selectedEsterilizavel: [], // IDs dos equipamentos filhos selecionados
      isLoadingEquipamento: false,
      isLoadingEsterilizavel: false,
    });
  },
  methods: {
    // Busca todos os equipamentos disponíveis
    async getEquipamento() {
      const LOADING_NAME = "get:equipamento";
      this.setLoading(LOADING_NAME, true);
      this.isLoadingEquipamento = true
      try {
        const { data } = await api.get("/equipamentos/nomeequipamentos/");
        data.sort((a, b) => {
          if (a.ativo !== b.ativo) {
            return a.ativo ? -1 : 1;
          }
          return a.nome.localeCompare(b.nome);
        });
        this.equipamentos = data;
      } catch (error) {
        this.$toast.error(`Erro ao buscar equipamentos: ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false);
        this.isLoadingEquipamento = false
      }
    },
    async getEsterilizavel() {
      const LOADING_NAME = "get:esterilizavel";
      this.setLoading(LOADING_NAME, true);
      this.isLoadingEsterilizavel = true
      try {
        const { data } = await api.get("/equipamentos/nomeesterelizavels/");
        this.esterilizavel = data.filter(item => item.ativo);
        // console.log(this.esterilizavel)
      } catch (error) {
        this.$toast.error(`Erro ao buscar esterilizaveis: ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false);
        this.isLoadingEsterilizavel = false
      }
    },
    // Busca o nome do equipamento pelo ID
    getEquipamentoNome(id) {
      const equipamento = this.equipamentos.find(equip => equip.id === id);
      return equipamento ? equipamento.nome : "";
    },
    getEsterilizavelNome(id) {
      const esterilizavel = this.esterilizavel.find(equip => equip.id === id);
      return esterilizavel ? esterilizavel.nome : "";
    },
    // Envia os dados selecionados
    localOnSubmit() {
      if (!this.selectedEquipamentoPai) {
        this.$toast.error("Por favor, selecione um Equipamento Principal.");
        return;
      }
      if (!this.selectedEquipamentosFilhos.length && !this.selectedEsterilizavel.length) {
        this.$toast.error("Por favor, selecione pelo menos um Equipamento ou Esterilizável Dependente.");
        return;
      }

      const fields = {
        equipamentopai: this.selectedEquipamentoPai,
        equipamentofilhos: this.selectedEquipamentosFilhos,
        esterilizavel: this.selectedEsterilizavel,
        ativo: true
      };
      // console.log('fields enviado criar ->', fields)

      this.onSubmit(fields); 
    },
  },
  computed: {
    // Computação para filtrar equipamentos dependentes
    filteredEquipamentos() {
      return this.equipamentos.filter(
        (equip) => equip.id !== this.selectedEquipamentoPai
      );
    },
    dependentesColuna1() {
      return this.selectedEquipamentosFilhos.filter((_, index) => index % 2 === 0);
    },
    dependentesColuna2() {
      return this.selectedEquipamentosFilhos.filter((_, index) => index % 2 !== 0);
    },
    dependentesColuna3() {
      return this.selectedEsterilizavel;
    }
  },
  mounted() {
    this.getEquipamento()
    this.getEsterilizavel()
  },
};
</script>
